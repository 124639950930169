<template>
<div class="table-content">
        <div class="table-options">
            <div class="delete" v-if="selected.length > 0">
                <vs-icon icon="delete"></vs-icon>
            </div>
        </div>
        <div class="table-wrapper with-border">
            <vs-table :data="bookings">
                <template slot="thead">
                    <vs-th sort-key="BkgFullReference">Reference</vs-th>
                    <vs-th sort-key="BkgTravelDate">Travel.Date</vs-th>
                    <vs-th sort-key="BkgConsultantCode">Agent</vs-th>
                    <vs-th sort-key="BkgStatusCode">Status</vs-th>
                    <vs-th sort-key="ValueBkgCost">Cost</vs-th>
                    <vs-th sort-key="ValueBkgRetail">Retail</vs-th>
                    <vs-th sort-key="ValueBkgAgent">Bkg.Agent</vs-th>
                    <vs-th sort-key="ValueTotalInvoiced">Invoiced</vs-th>
                    <vs-th sort-key="ValueTotalReceived">Received</vs-th>
                    <vs-th sort-key="TotalDiffAgentvsInv">AGENT.vs.INV</vs-th>
                    <vs-th sort-key="balance">Balance</vs-th>
                    <vs-th class="center"></vs-th>
                </template>
                <template slot-scope="{ data }">
                    <template :data="tr"  v-for="(tr, indextr) in data">
                        <vs-tr :key="indextr" :class="{'active': opened.includes(data[indextr].id)}">
                            <vs-td :data="data[indextr].BkgFullReference">
                                 {{ data[indextr].BkgFullReference }} 
                            </vs-td>
                            <vs-td :data="data[indextr].BkgTravelDate"> 
                                {{ data[indextr].BkgTravelDate | moment('timezone', 'Etc/GMT', 'll')}}
                            </vs-td>
                            <vs-td :data="data[indextr].BkgConsultantCode">
                                 {{ data[indextr].BkgConsultantCode }} 
                            </vs-td>
                            <vs-td :data="data[indextr].BkgStatusCode">
                                 {{ data[indextr].BkgStatusCode }} 
                            </vs-td>
                            <vs-td :data="data[indextr].ValueBkgCost">
                                 {{ $n(data[indextr].ValueBkgCost, "currency") }} 
                            </vs-td>
                            <vs-td :data="data[indextr].ValueBkgRetail">
                                 {{ $n(data[indextr].ValueBkgRetail, "currency")  }} 
                            </vs-td>
                            <vs-td :data="data[indextr].ValueBkgAgent">
                                 {{ $n(data[indextr].ValueBkgAgent, "currency")  }} 
                            </vs-td>
                            <vs-td :data="data[indextr].ValueTotalInvoiced">
                                 {{ $n(data[indextr].ValueTotalInvoiced, "currency")  }} 
                            </vs-td> 
                            <vs-td :data="data[indextr].ValueTotalReceived">
                                 {{ $n(data[indextr].ValueTotalReceived, "currency") }} 
                            </vs-td>
                            <vs-td :data="data[indextr].TotalDiffAgentvsInv" :class="data[indextr].TotalDiffAgentvsInv < 1 ? 'alert' : 'success'">  
                                 {{ $n(data[indextr].TotalDiffAgentvsInv, "currency")  }} 
                            </vs-td>
                            <vs-td :data="data[indextr].balance">                                
                                 {{ $n(data[indextr].balance, "currency")  }}
                            </vs-td>
                            <vs-td class="options">
                                <vs-icon icon="expand_more" @click="toggleRow(indextr)"></vs-icon>
                            </vs-td> 
                            
                        </vs-tr>
                        <vs-tr :key="`sub-${indextr}`" v-if="opened.includes(indextr)">
                            <vs-td colspan="13" class="noLineClamp">
                                <div class="bookingInfo">
                                    <div class="travel-info">
                                        <div class="item">
                                            <span class="subtitle">Booking Name</span>
                                            <p>{{  data[indextr].BkgName }}</p>
                                        </div>
                                        <div class="item">
                                            <span class="subtitle">Department Name</span>
                                            <p>{{  data[indextr].BkgDepartmentName }}</p>
                                        </div>
                                        <div class="item">
                                            <span class="subtitle">Booking Estado</span>
                                            <p>{{  data[indextr].BkgStatusName }}</p>
                                        </div>
                                        <div class="item">
                                            <span class="subtitle">Booking Closed</span>
                                            <p>{{  data[indextr].BkgClosed }}</p>
                                        </div>
                                        <div class="item">
                                            <span class="subtitle">Cantidad de viajeros</span>
                                            <p>{{  data[indextr].QuantityPax }}</p>
                                        </div>
                                        <div class="item">
                                            <span class="subtitle">Agencia</span>
                                            <p>{{  data[indextr].BkgAgentCode }}</p>
                                        </div>
                                    </div>
                                    <div class="service">
                                        <div class="notes">
                                            <div class="subtitle">Agente</div>
                                            <p>{{ data[indextr].BkgConsultantName }}</p>
                                            <span class="voucher">Código: {{ data[indextr].BkgConsultantCode }}</span>
                                        </div>
                                        <div class="notes">
                                            <div class="subtitle">Currency</div>
                                            <p>{{ data[indextr].BkgCurrency }} </p>
                                        </div>
                                        <div class="notes">
                                            <div class="subtitle">Bkg Mup $</div>
                                            <p>{{ $n(data[indextr].BkgCOMM, "currency")  }}</p>
                                        </div>
                                        <div class="notes">
                                            <div class="subtitle">Bkg Mup %</div>
                                            <p>{{ $n( data[indextr].BkgCOMMPercent, "percent")  }} </p>
                                        </div>
                                        <div class="notes">
                                            <div class="subtitle">Bkg COMM $</div>
                                            <p>{{ $n(data[indextr].BkgCOMM, "currency")  }} </p>
                                        </div>
                                        <div class="notes">
                                            <div class="subtitle">Bkg COMM %</div>
                                            <p>{{ $n( data[indextr].BkgCOMMPercent, "percent")  }} </p>
                                        </div>
                                        <div class="notes">
                                            <div class="subtitle">Total Credit Note</div>
                                            <p>{{ $n(data[indextr].ValueTotalCreditNote, "currency") }}  </p>
                                        </div>
                                    </div>
                                </div>
                            </vs-td>
                        </vs-tr>
                    </template>
                </template>
                <tr>
                    <td colspan=""></td>
                </tr>
            </vs-table>
        </div>
    </div>
</template>
<script>
export default {
    name: "TableBookingAnalysis",
    props: {
        items: { type: Array, default: () => [] },
    },
    data () {
        return {
            opened: [],
            selected:[],
            bookings: this.items,
        }
    },
    watch: {
        items(val){
            this.bookings = val
        }
    },
    methods: {
        toggleRow(id){
            const index   = this.opened.indexOf(id)
            this.opened = []
            if (index > -1) {
                this.opened.splice(index, 1)
                this.activeRow = -1
            } else {
                this.opened.push(id)
                this.activeRow = id
            }
        },
        getDiffValues(cost, paid){
            return cost - paid
        }
    }
}
</script>