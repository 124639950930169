<template>
    <div class="dashboard bookings sales analysis">
        <div class="head">
            <div class="title">Booking Financials Analysis</div>
        </div>

        <div class="summary" v-if="done">
            <div class="item">
                <div class="count">{{ $n(totalInvoiced, 'currency') }}</div>
                <div class="name">Bkg Invoiced</div>
            </div>
            <div class="item">
                <div class="count">{{ $n(totalReceived, 'currency') }}</div>
                <div class="name">Received</div>
            </div>
            <div class="item">
                <div class="count">{{ $n(totalDiffAgentvsInv, 'currency') }}</div>
                <div class="name">Agent vs Inv</div>
            </div>
            <div class="item">
                <div class="count">{{ $n(totalBalance, 'currency') }}</div>
                <div class="name">Balance</div>
            </div>
        </div>

        <div class="box-white filter">
            <vs-collapse>
                <vs-collapse-item icon-arrow="expand_more">
                    <div slot="header">
                        Buscador
                    </div>
                    <div class="wrapper">
                        <div class="item">
                            <vs-input label="Referencia" v-model="reference"/>
                        </div>
                        <div class="item">
                            <div class="wrap">
                                <label for="">Fechas</label>
                                <vs-select  class="creSelect" v-model="typeDate">
                                    <vs-select-item :key="index" :value="item.code" :text="item.name" v-for="(item,index) in filterDates" />
                                </vs-select>  
                            </div>
                            <input-date-picker v-model="date" :currentDate="date"></input-date-picker>                
                        </div>
                        <div class="item">
                            <label for="">Estado Reservación</label>
                            <multiselect v-model="stBookings" :options="bookStatus" :multiple="true" :max-height="160" placeholder="" 
                                :close-on-select="false" :clear-on-select="false" :preserve-search="false" label="DESCRIPTION" track-by="DESCRIPTION">
                                <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} estados seleccionados</span></template>
                            </multiselect>
                        </div>
                        <div class="item relative">
                            <div class='autocompleteel'> 
                                <div class="form-row">
                                    <vs-input type="text" label="Agencia" v-model="searchText" @keyup='loadSuggestions'/>
                                    <div class='suggestionlist' v-if="suggestiondata.length">
                                        <ul> 
                                            <li v-for='(item,index) in suggestiondata' @click='itemSelected(index)' :key="index">  {{ item.NAME }} </li> 
                                        </ul>
                                    </div> 
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <label for="">Agente</label>
                            <multiselect v-model="vendors" :options="consultants" :multiple="true" :max-height="160" placeholder="" open-direction="top"
                                :close-on-select="false" :clear-on-select="false" :preserve-search="false" label="NAME" track-by="NAME">
                                <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} agentes seleccionados</span></template>
                            </multiselect>
                        </div>
                        <div class="item">
                            <vs-button color="primary"  @click="search()" type="filled" icon="search"></vs-button>
                            <vs-button color="warning"  @click="reset()" type="border" icon="backspace" 
                                v-if="date != '' || reference != ''  || vendors.length > 0 || agencies.length > 0 || stBookings.length > 0"></vs-button>
                        </div>
                    </div>
                    <div class="tags">
                        <div class="wrapper-tags">
                            <template v-for="(item, idx) in agencies">
                                <vs-chip :key="idx" transparent color="primary" closable @click="removeAgency(item)">{{ item.NAME }}</vs-chip>
                            </template>
                        </div>
                    </div>
                </vs-collapse-item>
            </vs-collapse>
        </div>

        <div class="bookings-wrapper" v-if="status == 'done'">
            <table-financials :items="bkAnalysis.details"></table-financials>
            <!-- <table-booking-analysis :items="bkAnalysis"></table-booking-analysis> -->
        </div>

        <modal :show="modalShow" :dark="true" title="Código Acceso">
            <div slot="modal-body">
                <div class="modal-content" style="padding: 35px 35px 15px">
                    <p>Por favor ingresar el código de acceso</p>
                    <br>
                    <vs-input v-model="codeAccess"  :danger="errorCode" danger-text="Código incorrecto" v-on:keyup.enter="validAccess()"/>
                </div>
            </div>
            <div slot="modal-footer">
                <div class="modal-footer">
                    <vs-button color="primary" type="border" @click="validAccess()" :disabled="disabled">Ingresar</vs-button>
                </div>
            </div>
        </modal>

    </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
// import pagination from '@/components/Pagination.vue'
// import tableBookingAnalysis from '@/components/Tables/BookingAnalysis.vue'
import tableFinancials from '@/components/Tables/Financials.vue'
import inputDatePicker from '@/components/Inputs/input-date-range.vue'
import BookingService from '@/services/BookingService'
import CatalogService from '@/services/CatalogService'
import modal from "@/components/Modals/Basic.vue";
const  { mapActions: mapAppSettingActions } = createNamespacedHelpers('appSetting')

export default {
    name: "BookingsView",
    components: {
        // pagination,
        modal,
        // tableBookingAnalysis,
        tableFinancials,
        inputDatePicker
    },
    data () {
        return {
            bkAnalysis: [],
            key: 1,
            totalPost: 26,
            date: "",
            value: "All",
            reference: "",
            modalShow: true,
            codeAccess: "",
            errorCode: false,
            status: "",
            disabled: false,
            stBookings: [],
            bookStatus: [],
            types: [],
            vendors: [],
            agencies: [],
            consultants:[],
            searchText: "",
            suggestiondata:[],
            typeDate: "traveldate",
            filterDates: [
                // {
                //     code: "PaymentDue",
                //     name: "By Due Date"
                // },
                {
                    code: "traveldate",
                    name: "Travel Date"
                }
            ],
            totalInvoiced: 0,
            totalBalance: 0,
            totalDiffAgentvsInv: 0,
            totalReceived: 0,
            done: false
        }
    },
    async created () {
        let code =  JSON.parse(localStorage.getItem('CDG'));
        this.codeAccess = code
        if(this.codeAccess != "" || this.disabled != undefined){
            this.validAccess();
        }
        this.setDefaultValues();
        await this.getBookingAnalysis(); 
        await this.getBookingStatus();
        await this.getConsultants();
    },
    methods: {
        ...mapAppSettingActions(["LOADING_STATUS"]),
        async getBookingAnalysis(){            
            this.LOADING_STATUS(true);
            let response = await BookingService.getBookingFinancialsAnaysis({
                "begDate" : this.date != '' ? this.date.start : "", //opcional
                "endDate" : this.date != '' ? this.date.end : "",//opcional
                "reference" : this.reference, //opcional
                "agencies" :    this.agencies, //opcional
                "consultants" : this.vendors, //opcional
                "booking_status": this.stBookings,
                "typeDate": this.typeDate
            });            
            if((response.status/100) == 2){
                this.bkAnalysis = response.data
                this.totalInvoiced = this.bkAnalysis.totalInvoiced;
                this.totalBalance  = this.bkAnalysis.totalBalance;
                this.totalReceived = this.bkAnalysis.totalReceived;
                this.totalDiffAgentvsInv =  this.bkAnalysis.totalDiffAgentvsInv;
                this.done = true
            } 
          
            this.LOADING_STATUS(false);
        },
        async getBookingStatus(){
            this.LOADING_STATUS(true);
            this.bookStatus = await CatalogService.getBookingStatus({});            
            this.LOADING_STATUS(false);
        },
        async getConsultants(){
            this.LOADING_STATUS(true);
            this.consultants = await CatalogService.getConsultants({});            
            this.LOADING_STATUS(false);
        },
        handlePagination (_paged) {
            this.filter.page = _paged    
            // this.retrieveProperties()
        },
        search() {
            this.getBookingAnalysis()
        },
        reset() {
            this.date = "";
            this.reference = "";
            this.agencies  = [];
            this.vendors   = [];
            this.stBookings = [];
            this.setDefaultValues();
            this.getBookingAnalysis();
        },
        setDefaultValues(){
            this.stBookings = [ 
                { CODE: 'OK', DESCRIPTION: "Confirmed" },
                { CODE: 'IN', DESCRIPTION: "Invoiced" },  
                { CODE: 'PP', DESCRIPTION: "Partial Pay"}, 
                { CODE: 'PA', DESCRIPTION: "Paid"}
            ];
        },
        async loadSuggestions(){
            this.suggestiondata = [];
            if(this.searchText != ''){
                let response = await CatalogService.getAgencies({ search: this.searchText});
                console.log('response', response)
                if((response.status/100) == 2){
                    this.suggestiondata = response.data
                    // this.searchText = ''
                }
            }
            // else{
            //     this.filter.supplierId = ""
            // }
        },
        itemSelected(index){
            // this.searchText = this.suggestiondata[index].NAME;
            if(this.agencies.findIndex(item => item.CODE == this.suggestiondata[index].CODE) < 0){
                this.agencies.push(this.suggestiondata[index])
            }
            this.suggestiondata = [];
            this.searchText = ''
        },
        removeAgency (item) {
            this.agencies.splice(this.agencies.indexOf(item), 1)
        },
        validAccess() {
            if(this.codeAccess == "asuaire2022") {
                this.modalShow = false
                this.status = "done"
                localStorage.setItem('CDG', JSON.stringify(this.codeAccess))
            } else {
                this.errorCode = true
            }
        },
    }
}
</script>